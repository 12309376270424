import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faCoffee, faQuestion, faPhoneFlip } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
function Navbar() {
    // $(window).on('scroll', function () {
    //     if ($(window).scrollTop() > 50) {
    //         $('.top-head').addClass('fixed-menu');
    //     } else {
    //         $('.top-head').removeClass('fixed-menu');
    //     }
    // });

    const header = document.getElementById("#header");
    const output = document.getElementById("#output");
    const navigate = useNavigate()
    // header.addEventListener("scroll", (event) => {
    //     output.textContent = `scrollTop:  ${header.scrollTop}`;
    //     // header.classList.remove('sb-sidenav-toggled');
    // });

    return (
        <header id="header" className="top-head">

            <nav className="navbar navbar-default navbar-expand-lg navbar-light">

                <div className="container-fluid">

                    <Link className="navbar-brand" to="/">
                        <img src="../../assets/user/images/katec_logo.png" alt="katec_logo" />
                    </Link>

                    <div id="output">

                    </div>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <div className="col-md-4 col-sm-12">

                        </div>

                        <div className="col-md-8 col-sm-12">
                            <div className="right-nav">
                                {/* <div className="login-sr">
                                    <div className="login-signup">
                                        <ul>
                                            <li><a href="#">Đăng nhập</a></li>
                                            <li><a className="custom-b" href="#">Đăng ký</a></li>
                                        </ul>
                                    </div>
                                </div> */}

                                <div className="nav-b">
                                    <div className="nav-box">
                                        <ul>
                                            <li><a href='https://katec.vn/' target='_blank'
                                                style={{ cursor: 'pointer' }}>Trang chủ</a></li>
                                            <li><a href='https://katec.vn/gioi-thieu' target='_blank'
                                                //   onClick={()=> navigate("/about")} 
                                                style={{ cursor: 'pointer' }} >Giới thiệu</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Navbar;

