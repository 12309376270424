import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import '../../assets/user/css/style.css';
import '../../assets/user/css/responsive.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneFlip } from '@fortawesome/free-solid-svg-icons';
import { faFacebookMessenger, } from '@fortawesome/free-brands-svg-icons'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import Navbar from './Navbar';
// import Sidebar from './Sidebar';
import Footer from './Footer';
import $ from 'jquery';

$(window).scroll(function () {
    if ($(this).scrollTop() > 150) {
        $('.container-fluid').css({ 'margin-top': 0 });
    } else {
        $('.container-fluid').css({ 'margin-top': '35px' });
    }
})


function MasterLayout() {
    return (
        <div className='sb-nav-fixed' id="sb-nav-fixed">
            <div style={{
                padding: '5px',
                backgroundColor: ' #3d4095',
                top: '0',
                width: '100%', height: '35px',
                zIndex: '1049',
                position: 'fixed',
                paddingLeft: '2%',
                justifyContent: 'space-between',
                display: 'flex'
            }}>
                <a href="tel:+84889881010" style={{ color: 'white' }}>

                    Hotline:  <FontAwesomeIcon icon={faPhoneFlip} /> 0889881010
                </a>
                {/* <a href="https://www.facebook.com/kateccantho">
                    <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a href="https://zalo.me/0932927007">
                    <i className="fw-bold" >Zalo</i>
                </a> */}
                <div >
                    <ul>

                        <li className='list-inline-item px-1'>
                            <a href="https://www.facebook.com/kateccantho" style={{ color: 'white' }} >
                                <FontAwesomeIcon icon={faFacebookF} />
                            </a>
                        </li>

                        <li className='list-inline-item px-1'>
                            <a href="https://zalo.me/0932927007" style={{ color: 'white' }}>
                                <i className="fw-bold" >Zalo</i>
                            </a>
                        </li>

                    </ul>
                </div>
            </div>
            <Navbar />

            <div id="layoutSidenav">
                {/* <div id="layoutSidenav_nav"> */}
                {/* <Sidebar /> */}
                {/* </div> */}

                <div id="layoutSidenav_content" >
                    <main>
                        <Outlet />

                        <div style={{
                            display: 'inline-grid', marginTop: '300px',
                            position: 'fixed',
                            bottom: ' 70px',
                            // left: '50px',
                            right: '-100px',
                            // minWidth: '200px'
                        }}>
                            <a href="tel:0889881010"
                                title='Hotline: 0889881010'
                                className='call-icon'
                            //   rel="nofollow"f
                            >
                                {/* test ring*/}
                                <div class="wrapper">
                                    <div class="ring">
                                        <div class="coccoc-alo-phone coccoc-alo-green coccoc-alo-show">
                                            <div class="coccoc-alo-ph-circle"></div>
                                            <div class="coccoc-alo-ph-circle-fill"></div>
                                            <div class="coccoc-alo-ph-img-circle"></div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <br></br>

                            <a href="https://www.facebook.com/messages/t/1016769568527370 " target='_blank' className="mes"
                                title='Nhắn tin FaceBook'
                            >
                                <FontAwesomeIcon icon={faFacebookMessenger} size='2xl' style={{
                                    height: '40px', width: '40px', marginLeft: '-10px'
                                }} shake />

                                {/* test----- */}

                                <span></span>
                            </a>
                            <br />
                        </div>
                        {/* <div style={{
                            display: 'inline-grid', marginTop: '300px',
                            position: 'fixed',
                            bottom: ' 20px',
                            // left: '50px',
                            right: '50px',
                            fontSize:'50px'
                        }} id='scroll'>
                            <a href='#' title="Back to top" id='scroll'>&#710;</a>
                        </div> */}
                    </main>
                    <Footer />
                </div>
            </div >
        </div >
    );
}

// export default MasterLayout;

function myParams(Component) { //Back link AND use "params"
    return props => <Component navHook={useNavigate()} />;
}

export default myParams(MasterLayout);
