import React from 'react';
import { Link } from 'react-router-dom';

const Success = () => {
    return (
        <div id="layoutError_content">
            <main>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="text-center mt-4">
                                {/* <img className="mb-4 img-error" src="/assets/images/404-error-page-not-found.png" alt="error404" /> */}

                                <h4>  Kính chào quí khách! </h4>
                                <p className='text-start'>   Công ty KATEC chuyên về giải pháp trong lĩnh vực CNTT và Điện nhà thông minh (Smarthome). Có trụ sở chính tại: số 189 Phan Huy Chú, phường An Khánh, quận Ninh Kiều, Thành phố Cần Thơ. Mã số thuế: 1801633969. Chuyên cung cấp phần mềm quản lý các lĩnh vực: Café, ăn uống, Shop, Spa, hiệu thuốc, siêu thị mini,… Thật tiện lợi cho khách hàng khi quản lý vì phần mềm hoạt động trên tất cả các thiệt bị hiện có trên thị trường từ xa qua mạng internet và với phần mềm quản lý bán hàng Tpos hỗ trợ bán Livestream trên Facebook rất tiện dụng trong quản lý kinh doanh trên nền tảng Internet hiện nay.
                                </p>
                                <img src="../../../assets/user/images/hinh-cty-katec.png" className="icon-small" alt="Hình ảnh công ty Katec" />
                                <p className='text-start'> 
                                    Về các thiết bị kèm theo như máy in nhiệt, in decal nhiệt, máy quét mã vạch, két tiền, giấy in nhiệt, decal nhiệt chính hãng, phân phối sỉ toàn quốc Đặt biệt là đội ngũ chăm sóc khách hàng chuyên nghiệp Katec sẽ mang lại cho khách hàng an tâm và hiệu quả cao nhất khi sử dụng sản phẩm chúng tôi cung cấp.
                                </p>
                                Cảm ơn!


                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Success;