import { BrowserRouter, Routes, Route } from "react-router-dom";

import "sweetalert/dist/sweetalert.min";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import MasterLayout from "../layouts/user/MasterLayout";
import Error404 from "../components/errors/Error404";
import Success from "../components/user/home/Success";
import HomeIndex from "../components/user/home/HomeIndex";
import About from "../components/user/home/About";
export default function router() {
  return (
    <>
      <ToastContainer />

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MasterLayout />}>
            <Route index element={<HomeIndex />} />
            <Route path="dangkythanhcong" element={<Success />} />
            <Route path="about" element={<About />} />
            <Route path="*" element={<Error404 />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}
